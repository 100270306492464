/*
 * @Author: 王枫叶
 * @Date: 2020-07-20 20:48:00
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-10-23 13:54:17
 */
import "@/assets/style/reset.css";
import "@/plugins/flexible.js";
import "@/plugins/scale_no.js";
import "./plugins/element.js";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import VueTouch from "vue-touch-hotfix";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAnalytics from "vue-analytics";
import VueAwesomeSwiper from "vue-awesome-swiper";
import { Loading } from "vant";
import { Popup } from "vant";
import "vant/lib/popup/style";
Vue.use(Loading);
Vue.use(Popup);
Vue.use(VueAwesomeSwiper);
Vue.use(VueTouch, {
  name: "v-touch"
}); //触摸插件
// 谷歌统计页面追踪
Vue.use(VueAnalytics, {
  id: "UA-165940626-2",
  router,
  autoTracking: {
    pageviewOnLoad: false
  }
});
Vue.use(VueClipboard);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App),
  data: {},
  created() {}
}).$mount("#app");
