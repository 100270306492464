<template>
  <div class="pcm-wrapper">
    <div class="header-container">
      <div class="h-content">
        <div class="logo"></div>
        <div class="contactDetails">
          <div class="contact-container">
            <div class="telegram" @click="toTg"></div>
            <!-- <div class="twitter" @click="toTwitter"></div> -->
            <div class="potato" @click="toPotato"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="txt"></div> -->
    <div class="infoBox">
      <div class="text-container">
        <div class="text-1">
          <div class="mask"></div>
        </div>
        <div class="text-2"></div>
      </div>

      <div class="down-load-container">
        <div class="btnBox">
          <div class="iosBtn" @click="openQrcode('ios')"></div>
          <div class="androidBtn" @click="openQrcode('android')"></div>
        </div>
        <div
          class="hover-code"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
        ></div>
        <div class="qrcodeBox" v-if="hover">
          <div class="qrcodeContent">
            <template>
              <div :class="isCheck ? 'active' : 'unActive'">
                <img class="qrcode" :src="qrImg" alt="" />
              </div>
            </template>
          </div>
          <!-- <div class="ucTip"></div> -->
        </div>
      </div>
    </div>

    <div class="bottom_tip">
      本平台主要收益为广告赞助，且保证APP安全无毒，如遇报毒提醒请忽略！
    </div>
  </div>
</template>
<script>
export default {
  props: ["qrImg", "os"],
  data() {
    return {
      iosQr: false,
      androidQr: false,
      iosBtnImg: require("@/assets/images/pc/iosBtn.png"),
      androidBtnImg: require("@/assets/images/pc/androidBtn.png"),
      isCheck: false,
      hover: false,
      timer: null,
      showType: ""
    };
  },
  mounted() {},
  destroyed() {
    clearTimeout(this.et);
  },
  methods: {
    getApkInfo(type) {
      this.$emit("getApkInfo", type);
    },
    openQrcode(type) {
      if (this.showType == type) {
        this.showType = "";
        this.hover = false;
      } else {
        this.showType = type;
        if (this.hover) {
          clearTimeout(this.timer);
          this.timer = null;
          this.hover = false;
          this.timer = setTimeout(() => {
            this.hover = true;
          }, 400);
        } else {
          this.hover = true;
        }
      }
    },
    checkFn() {
      this.isCheck = !this.isCheck;
      setTimeout(() => {
        this.isCheck = !this.isCheck;
      }, 1500);
    },
    //tg
    toTg() {
      window.location.href = "https://t.me/fenyou1";
    },
    toTwitter() {
      window.location.href = "https://pzcp.org/fenyou";
    },
    toPotato() {
      window.location.href = "https://ptcc.in/fenyou";
    },
    viewQr(type) {
      if (type === "ios") {
        this.androidQr = false;
        this.androidBtnImg = require("@/assets/images/pc/androidBtn.png");
        this.iosQr = !this.iosQr;
        if (this.iosQr) {
          this.iosBtnImg = require("@/assets/images/pc/iosBtnFocus.png");
        } else {
          this.iosBtnImg = require("@/assets/images/pc/iosBtn.png");
        }
      } else {
        this.iosQr = false;
        this.iosBtnImg = require("@/assets/images/pc/iosBtn.png");
        this.androidQr = !this.androidQr;
        if (this.androidQr) {
          this.androidBtnImg = require("@/assets/images/pc/androidBtnFocus.png");
        } else {
          this.androidBtnImg = require("@/assets/images/pc/androidBtn.png");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url("../../../assets/images/pc/bg.png") no-repeat center;
  background-size: 100% 100%;
  background-size: cover;
  .header-container {
    width: 100%;
    height: 100px;
    margin: 0 0 199px;
    // background-color: rgba(255, 255, 255, 0.2);
    .h-content {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      position: relative;
    }
  }
  .txt {
    width: 430px;
    height: 150px;
    position: fixed;
    display: flex;
    top: 200px;
    right: 110px;
    background: url("../../../assets/images/pc/txt.png") no-repeat center;
    background-size: 100% 100%;
    background-size: cover;
  }
  .logo {
    width: 109px;
    height: 80px;
    flex-grow: 0;
    margin: 0 1018px 0 0;
    background: url("../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 200px;
    top: 10px;
  }
  .text-container {
    height: 500px;
    position: absolute;
    left: 0;
    width: 800px;
    .text-2 {
      width: 469px;
      position: absolute;
      left: 730px;
      bottom: 54px;
      height: 170px;
      background: url(../../../assets/images/pc/pc_text2.png) no-repeat center;
      background-size: 100% auto;
    }
    .text-1 {
      width: 700px;
      position: absolute;
      bottom: -40px;
      height: 400px;
      background: url(../../../assets/images/pc/pc_text1.png) no-repeat center;
      background-size: 100% auto;
      // .mask{
      //   width: 100%;
      //   height: 100%;
      //   background:rgba(0,0,0,.1);
      // }
    }
  }
  .contactDetails {
    position: absolute;
    top: 26px;
    right: 20px;
    display: flex;
    width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    .contact-container {
      position: absolute;
      height: 80px;
      width: 240px;
      right: 0;
      top: -10px;
    }
    div {
      width: 92px;
      height: 54px;
      margin-left: 26px;
      display: inline-block;
    }
    .telegram {
      cursor: pointer;
      background: url(../../../assets/images/pc/telegram.png) no-repeat center;
      background-size: 100% 100%;
    }
    .twitter {
      cursor: pointer;
      background: url("../../../assets/images/pc/twitter.png") no-repeat center;
      background-size: 100% 100%;
    }
    .potato {
      cursor: pointer;
      background: url("../../../assets/images/pc/patato.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .title {
    height: 165px;
    width: 582px;
    background: url("../../../assets/images/pc/title.png") no-repeat center;
    background-size: 100% 100%;
    position: fixed;
    top: 170px;
    left: 80px;
  }
  .infoBox {
    position: fixed;
    display: flex;
    top: 80px;
    height: 600px;
    width: 1200px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);

    .qrcodeBox {
      display: flex;
      flex-direction: column;
      width: 170px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      position: absolute;
      right: 0;
      bottom: 80px;
      .qrcodeContent {
        width: 165px;
        height: 165px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 2px solid #fff;
        background: #fff;
        overflow: hidden;
        .qrcode {
          width: 150px;
          height: 150px;
          background-color: #fff;
        }
        .active {
          animation: activeActive 2s;
          -webkit-animation: activeActive 2s; /* Safari 与 Chrome */
          animation-timing-function: linear;
          -webkit-animation-timing-function: linear;
        }
        @keyframes activeActive {
          0% {
            outline: 0;
          }
          50% {
            outline: 10px solid #fff;
          }
          100% {
            outline: 0;
          }
        }
      }
      .ucTip {
        height: 34px;
        width: 157px;
        background: url("../../../assets/images/pc/uc_tip.png") no-repeat;
        background-size: 100% 100%;
        margin: 5px auto;
      }
    }
    .hover-code {
      width: 61px;
      height: 61px;
      background: url("../../../assets/images/pc/code.png") no-repeat;
      background-size: 100% auto;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
    .down-load-container {
      width: 470px;
      height: 70px;
      position: absolute;
      right: 0;
      bottom: 93px;
    }
    .btnBox {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      div {
        width: 203px;
        height: 61px;
      }
      .iosBtn {
        cursor: pointer;
        background: url("../../../assets/images/pc/iosBtn.png") no-repeat;
        background-size: auto 100%;
        margin-right: 10px;
      }
      .androidBtn {
        cursor: pointer;
        background: url("../../../assets/images/pc/androidBtn.png") no-repeat;
        background-size: auto 100%;
        margin-right: 10px;
      }
      .desc {
        width: 209px;
        height: 20px;
        background-image: url("../../../assets/images/pc/uc_tip.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .bottom_tip {
    height: 40px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    // background: url("../../../assets/images/pc/bottom_tip.png") no-repeat center;
    background-size: 100% 100%;
    position: fixed;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, 0);
  }
}
</style>
