<template>
  <div class="mobilePage" id="mobile">
    <div class="main">
      <div class="headerBox">
        <div class="logo"></div>
        <div class="contactDetails">
          <div class="telegram" @click="toTg"></div>
          <div class="potato" @click="toPotato"></div>
          <!-- <div class="twitter" @click="toTwitter"></div> -->
        </div>
      </div>
      <div class="iosBtn" v-if="os.isPhone" @click="getApkInfo('ios')"></div>
      <div class="androidBtn" v-else @click="getApkInfo('android')"></div>
      <div class="info_box">
        <div class="kind_tip"></div>
        <div class="a_tip_logo">官网：FENU.CC</div>
        <div class="ios_text_tip" v-if="os.isPhone"></div>
        <div class="a_tip"></div>
        <div class="ios_tip" v-if="os.isPhone"></div>
        <div class="android_tip" v-if="!os.isPhone"></div>
      </div>
    </div>
    <!-- loading-->
    <div class="loading_box" v-if="loading">
      <div style="height: 5px"></div>
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>加载中...</h3>
    </div>
  </div>
</template>
<script>
import handleReplaceURLParams from "@/utils/handleReplaceURLParams";
import getApkInfo1 from "@/api/getApkInfo";
export default {
  props: ["os", "switchData", "ip", "loading"],
  created() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      this.page_url = window.location.href;
    },
    getApkInfo(type) {
      if (type === "android") {
        this.$emit("getApkInfo", type);
      } else if (type === "ios") {
        this.$emit("getApkInfo", type);
      }
    },
    //获取H5下载链接
    getiosBeta() {
      var params = handleReplaceURLParams()
        ? handleReplaceURLParams()
        : JSON.stringify(this.$route.query) === "{}"
        ? "{}"
        : JSON.stringify(this.$route.query);
      const param = {
        appId: 25,
        ip: this.$route.query.ip ? this.$route.query.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(params),
          ...this.cutInfo
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal: "ios",
        packageType: 5
      };
      getApkInfo1(param).then(res => {
        if (res.data.code === 200) {
          // H5
          var url = res.data.data.link;
          let dc = this.$route.query.dc;
          let ch = this.$route.query.ch;
          let pc = this.$route.query.pc;
          let linkUrl = "";
          if (dc) {
            linkUrl = url + "?dc=" + dc;
          } else if (ch) {
            linkUrl = url + "?ch=" + ch;
          } else if (pc) {
            linkUrl = url + "?pc=" + pc;
          } else {
            linkUrl = url;
          }
          this.$router.push({
            name: "h5_ios",
            query: Object.assign(this.$route.query, {
              linkUrl: window.btoa(linkUrl)
            })
          });
        }
      });
    },
    toTg() {
      window.location.href = "https://t.me/fenyou1";
    },
    toTwitter() {
      window.location.href = "https://twitter.com/fenyou";
    },
    toPotato() {
      window.location.href = "https://pzcp.org/fenyou";
    }
  }
};
</script>
<style lang="scss" scoped>
.mobilePage {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background: #000;
  .main {
    width: 100%;
    background: url("../../../assets/images/mobile1/bg.png") no-repeat;
    background-size: 100% 560px;
    .headerBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 17px 0;
      .logo {
        width: 38px;
        height: 38px;
        background: url("../../../assets/images/mobile1/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .contactDetails {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          height: 32px;
          width: 55px;
          margin-right: 8px;
        }
        .telegram {
          background: url("../../../assets/images/mobile1/telegram.png")
            no-repeat;
          background-size: 100% auto;
        }
        .twitter {
          background: url("../../../assets/images/mobile1/twitter.png")
            no-repeat;
          background-size: 100% auto;
        }
        .potato {
          background: url("../../../assets/images/mobile1/potato.png") no-repeat;
          background-size: 100% auto;
        }
      }
    }
    .iosBtn {
      height: 62px;
      width: 238px;
      background: url("../../../assets/images/mobile1/iosBtn.png") no-repeat;
      background-size: 100% auto;
      margin: 520px auto 10px;
    }
    .androidBtn {
      height: 52px;
      width: 182px;
      background: url("../../../assets/images/mobile1/androidBtn.png") no-repeat;
      background-size: 100% auto;
      margin: 520px auto 0;
    }
    .info_box {
      padding: 10px;
      .kind_tip {
        height: 89px;
        width: 327px;
        margin: 20px auto 0;
        display: none;
        background: url("../../../assets/images/mobile1/kind_tip.png") no-repeat
          center;
        background-size: 100% 100%;
      }
      .ios_text_tip {
        height: 15px;
        width: 282px;
        display: none;
        margin: 15px auto 15px;
        margin-bottom: 80px;
        background: url("../../../assets/images/mobile1/ios_text_tip.png")
          no-repeat center;
        background-size: 100% 100%;
      }
      .ios_tip {
        height: 440px;
        width: 100%;
        margin: 0 auto;
        background: url("../../../assets/images/mobile1/ios_tip.png") no-repeat;
        background-size: 104% auto;
        background-position: top;
        background-position-y: 0px;
      }
      .a_tip_logo {
        width: 100%;
        margin: 20px 0;
        margin-top: 10px;
        font-size: 18px;
        text-align: center;
        color: #fff;
      }
      .a_tip {
        height: 122px;
        width: 100%;
        margin: 4px auto 0;
        background: url("../../../assets/images/mobile1/a_tips.png") no-repeat;
        background-size: 94% auto;
        background-position: center;
      }
      .android_tip {
        height: 610px;
        width: 100%;
        margin: 4px auto 0;
        margin-bottom: 20px;
        background: url("../../../assets/images/mobile1/android_tip.png")
          no-repeat;
        background-size: 100% auto;
        background-position: top;
      }
    }
  }
}
/* loading */
.loading_box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  border-radius: 20px;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    padding-top: 10px;
    font-size: 20px;
    color: #fff;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//loading
</style>
